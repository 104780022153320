import { Suspense } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, route }) => {
  if (!route) return <Navigate to="/misc/not-authorized" replace />;

  try {
    const localStorageUser = JSON.parse(localStorage.getItem("userData"));
    const sessionStorageUser = JSON.parse(sessionStorage.getItem("userData"));

    if (localStorageUser || sessionStorageUser) {
      return <Suspense fallback={null}>{children}</Suspense>;
    }

    return <Navigate to="/misc/not-authorized" replace />;
  } catch {
    return <Navigate to="/misc/not-authorized" replace />;
  }
};

export default PrivateRoute;
