// import { Home } from "react-feather";

// {
//   id: "dashboard",
//   title: "Dashboard",
//   icon: <Home size={20} />,
//   navLink: "/home",
// },

export default [];
