// import { lazy } from "react";

// const Dashboard = lazy(() => import("../../views/dashboard/Dashboard"));

// const DashboardRoutes = [
//   {
//     path: "/home",
//     element: <Dashboard />,
//   },
// ];

export default [];
