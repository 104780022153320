import { createSlice } from "@reduxjs/toolkit";
import { getUserData, isUserLoggedIn } from "../utility/Utils";

const initialState = {
  status: isUserLoggedIn(),
  userData: getUserData(),
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    refetchUserData: (state) => {
      state.userData = getUserData();
      state.status = isUserLoggedIn();
    },
  },
});

export const { refetchUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
