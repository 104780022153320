import {
  BookOpen,
  Box,
  Cpu,
  Globe,
  List,
  PlusCircle,
  Square,
  User,
  Users,
} from "react-feather";

export default [
  // {
  //   id: "activity",
  //   title: "Activity",
  //   icon: <Activity size={20} />,
  //   navLink: "/activity",
  // },
  // {
  //   id: "others",
  //   title: "Others",
  //   icon: <FileText size={20} />,
  //   navLink: "/others",
  // },
  {
    id: "books",
    title: "Books",
    icon: <BookOpen size={20} />,
    children: [
      {
        id: "all-books",
        title: "All Books",
        icon: <List size={20} />,
        navLink: "/books/all-books",
      },
      {
        id: "featured-book",
        title: "Featured Books",
        icon: <Globe size={20} />,
        navLink: "/books/featured-books",
      },
      {
        id: "categories",
        title: "Categories",
        icon: <Square size={20} />,
        navLink: "/categories",
      },
      {
        id: "languages",
        title: "Languages",
        icon: <Globe size={20} />,
        navLink: "/languages",
      },
    ],
  },

  {
    id: "publishers",
    title: "Publishers",
    icon: <Cpu size={20} />,
    navLink: "/publishers",
  },
  {
    id: "authors",
    title: "Authors",
    icon: <User size={20} />,
    navLink: "/authors",
  },
  {
    id: "users",
    title: "Users",
    icon: <User size={20} />,
    navLink: "/users",
  },
  {
    id: "sellers",
    title: "Sellers",
    icon: <Users size={20} />,
    navLink: "/sellers",
  },
  {
    id: "librarians",
    title: "Librarians",
    icon: <Users size={20} />,
    navLink: "/librarians",
  },
  {
    id: "library-members",
    title: "Library Members",
    icon: <Users size={20} />,
    navLink: "/library-members",
  },
  {
    id: "requests",
    title: "Requests",
    icon: <Box size={20} />,
    navLink: "/requests",
  },
  {
    id: "subscriptions",
    title: "Subscriptions",
    icon: <PlusCircle size={20} />,
    navLink: "/subscription-packages",
  },
  // {
  //   id: "news",
  //   title: "News",
  //   icon: <Edit size={20} />,
  //   navLink: "/news",
  // },
  // {
  //   id: "manage-ads",
  //   title: "Manage Ads",
  //   icon: <BarChart size={20} />,
  //   navLink: "/manage-ads",
  // },
  // {
  //   id: "setting",
  //   title: "Setting",
  //   icon: <Settings size={20} />,
  //   navLink: "/setting",
  // },
];
